import React from "react";
import { Upload, message } from "antd";
import { s3Client } from "../../sdk/s3";

class S3Upload extends React.Component {
  state = {
    fileList: [],
  };

  componentDidMount() {
    this.updateFileList(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.updateFileList(this.props.value);
    }
  }

  updateFileList = (value) => {
    if (!value) {
      this.setState({ fileList: [] });
      return;
    }

    const fileList = Array.isArray(value) ? value : [value];
    const formattedFileList = fileList.map(file => ({
      uid: file.uid || `rc-upload-${Date.now()}`,
      name: file.name,
      size: file.size,
      type: file.type,
      lastModified: file.lastModified,
      lastModifiedDate: file.lastModifiedDate,
      percent: 100,
      status: 'done',
      url: file.url,
      response: {
        url: file.url,
        key: file.key,
      },
    }));

    this.setState({ fileList: formattedFileList });
  };

  handleUpload = async ({ file, onProgress, onSuccess, onError }) => {
    const key = `${Date.now()}-${file.name}`;
    try {
      const result = await s3Client.upload(key, file, {
        onProgress: progress => {
          onProgress({ percent: (progress.loaded / progress.total) * 100 });
        },
      });

      const fileInfo = {
        uid: file.uid,
        name: file.name,
        size: file.size,
        type: file.type,
        lastModified: file.lastModified,
        lastModifiedDate: file.lastModifiedDate,
        percent: 100,
        status: "done",
        url: result.url,
        key: key,
        response: {
          url: result.url,
          key: key,
        },
      };

      onSuccess(fileInfo);
    } catch (err) {
      onError(err);
      message.error("上传失败");
    }
  };

  onRemove = async (file) => {
    try {
      const { fileList } = this.state;
      const newFileList = fileList.filter(item => item.uid !== file.uid);
      this.setState({ fileList: newFileList });

      if (this.props.onChange) {
        this.props.onChange(newFileList);
      }

      return true;
    } catch (err) {
      message.error("删除文件失败");
      return false;
    }
  };

  onChange = ({ file, fileList }) => {
    const newFileList = fileList.map(f => {
      if (f.uid === file.uid) {
        return { ...f, status: file.status, percent: file.percent };
      }
      return f;
    });

    this.setState({ fileList: newFileList });

    if (file.status === 'done' || file.status === 'removed') {
      if (this.props.onChange) {
        const simplifiedFileList = newFileList.map(f => ({
          uid: f.uid,
          name: f.name,
          size: f.size,
          type: f.type,
          lastModified: f.lastModified,
          lastModifiedDate: f.lastModifiedDate,
          percent: f.percent || 100,
          status: f.status,
          url: f.response?.url || f.url,
          key: f.response?.key || f.key,
          response: {
            url: f.response?.url || f.url,
            key: f.response?.key || f.key,
          },
        }));

        this.props.onChange(simplifiedFileList);
      }
    }
  };

  render() {
    const { children, onChange, value, ...restProps } = this.props;
    const { fileList } = this.state;

    return (
      <Upload
        customRequest={this.handleUpload}
        onChange={this.onChange}
        onRemove={this.onRemove}
        fileList={fileList}
        {...restProps}
      >
        {children}
      </Upload>
    );
  }
}

export default S3Upload;